import { ref, onMounted, onBeforeUnmount } from 'vue';

const isMobile = ref(false);
const isLg = ref(false);
const isSm = ref(false);
const isMd = ref(false);
const isXl = ref(false);
const is2Xl = ref(false);
const currentWidth = ref(0);

const updateSize = () => {
  isMobile.value = window.innerWidth <= 768;
  isSm.value = window.innerWidth >= 640;
  isMd.value = window.innerWidth >= 768;
  isLg.value = window.innerWidth >= 1024;
  isXl.value = window.innerWidth >= 1280;
  is2Xl.value = window.innerWidth >= 1536;
  currentWidth.value = window.innerWidth;
};

const useScreenSize = () => {
  onMounted(() => {
    updateSize();
    window.addEventListener('resize', updateSize);
  });

  onBeforeUnmount(() => {
    window.removeEventListener('resize', updateSize);
  });

  return {
    isMobile,
    isSm,
    isMd,
    isLg,
    isXl,
    is2Xl,
    currentWidth,
  };
};

export default useScreenSize;
